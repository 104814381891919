@charset "utf-8"; 

/* *******************************************************
    filename : nft.css
    description : nft layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* ==================== nft stylings ====================== */
.main__tit{
  font-size: 1.4em;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 60px;
}
.nft-item-wrap{
  @include dspFlexCenter();
}

.nft-img-wrap{
  position: relative;
  @include dspFlexCenter();
  flex-direction: column;
  width: fit-content !important;
  border-radius: 10px;
  transition-duration: 0.3s;

    &:hover{
      transform: scale(1.1);
      cursor: pointer;
    }

  .nft-img{
    border-radius: 10px;
    transition-duration: 0.3s;
    &:hover{
      transform: scale(1.1);
      cursor: pointer;
    }
  }
  .nft-img-title{
    margin-top: 10px;
  }
  .nft-black-box{
    width: 189.5px;
    height: 189.5px;
    border-radius: 10px;
    background: rgb(61,61,61);
    background: radial-gradient(circle, rgba(61,61,61,1) 0%, rgba(22,22,22,1) 70%);
    @include dspFlexCenter();
    .comingsoon-text{
      background-color: black;
      padding: 10px 20px;
      border-radius: 20px;
    }
  }
}

.nft-img__Coming-soon{
  border-radius: 10px;
  min-width: 189.5px;
  min-height: 189.5px;
}

.skeleton{
  background-color: rgb(200, 200, 200, 11%) !important;
  border-radius: 10px;
  min-height: 189.5px;
  pointer-events: none !important;

}


  /* media stylings =================================== */
  @include breakPoint(1280px){
    .nft-img{
      max-width: 189.5px !important;
      max-height: 189.5px !important;
    }
    .nft-img__Coming-soon{
      max-width: 189.5px !important;
      max-height: 189.5px !important;
    }
  }

  @include breakPoint(1000px){
    .nft-img-wrap{
      @include dspFlexCenter();
    }
    .nft-wrap{
      @include dspFlexCenter();
    }
    .nft-img__Coming-soon{
      min-width: initial !important;
      min-height: initial !important; 
    }

  }
  @include breakPoint(480px){
    .main__tit{
      font-size: 1em !important;
      margin-bottom: 35px;
    }
    .nft-wrap{
      gap: 20px !important;
    }
  }