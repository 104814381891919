@charset "utf-8"; 

// import
@import '../../media/css/common.scss';

.index__exchage__box{
    @include dspFlexCenter;
    margin-top: 24px;    
    .exchage__tit,.exchage__txt{
        letter-spacing: -0.08px;
        font-size: 13px !important;
    }
    .exchage__list{
        @include dspFlexCenter;
        padding-left: 18px;
        .exchage__txt{
            color:#fff;
            span + span {padding-left: 4px;}
        }
        .line{
            width: 1px;
            height: 8px;
            margin: 0 8px;
            background: #fff;
        }
        .last__alignment__item{padding-top:0;}
    }
    &--mob{display: none;}
}