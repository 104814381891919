@charset "utf-8"; 

/* *******************************************************
    filename : swap.css
    description : swap layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* ==================== toggle menu stylings ====================== */
.toggle__head {
    @include flexCenter;
    @include border-radius(50px);
    width: 194px;
    height: 48px;
    margin: auto;
}

.toggle__menu {
    @include dspFlexCenter;
    height: 55px;
    padding: 0 5px;
    @include border-radius(50px);
    background: #202226;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.75), inset -2px -2px 2px #4E5154;
    .toggle__container {
        display: flex;
        position: relative;
    }
    .toggle__menu__tit {
        @include boxsizing;
        @include border-radius(100px);
        flex: 0 1 auto;
        position: relative;
        width: 95px;
        height: 45px;
        font-family: var(--font2);
        @include font(normal, bold, 15px, rgba(255, 255, 255, 0.5), 24px);
        letter-spacing: -0.41px;
        z-index: 1;
        &:hover{
            background: rgba(54, 158, 73, 0.1);
            color: #00CAFF;
            transition: all 0.3s ease-in-out;
        }
        &.active {
            padding: 2px;
            background: linear-gradient(
                        -180deg,#29869f 14.75%,#00caff 84.81%),linear-gradient(
                        -180deg,#0190aa 14.75%,#00a2d3 84.81%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            color: #202024;
            cursor: default;
            transition: all 0.3s ease-in-out;
        }
    }
    .tab__indicator {display: none;}
    &.on {
        .toggle__menu__tit.active {
            background:transparent;
        }
        .tab__indicator {
            @include position(absolute,0,auto,0,0);
            @include border-radius(100px);
            display: block;
            padding: 2px;
            background: linear-gradient(320.66deg, #369E49 14.75%, #48DC63 84.81%),linear-gradient(320.66deg, #01AA68 14.75%, #00D381 84.81%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            transition: all 0.3s ease-in-out;
            pointer-events: none;
        }
    }
}

/* ==================== tab-content stylings ====================== */
.tab-content {
    display: none;
    &.current {display: block;}
}

/* ==================== select stylings ====================== */
.custom__select__type{
    position: relative;
    width: 188px;
}
.custom__select__area {
    position: relative;
    width: 190px;
    @include border-radius(11px);
    cursor: pointer;
    @include boxsizing;
    .custom__select{
        @include flexCenter;
        position: relative;
        width: 190px;
        height: 100%;
        padding: 5px 0;
        @include boxsizing;
        .custom__select__text {
            padding-left: 9px;
            font-size: 20px;
            letter-spacing: -1.2px;
        }
        &.on{
            border-top-left-radius: 11px;
            border-top-right-radius: 11px;
            background: #272B30;
            .custom__select__ico{padding-left: 10px}
            .custom__select__arrow{transform: rotate(180deg); top:14px;}
        }
        &.full__custom__select{
            width: 180px;
            .plus__ico{margin: 0 8px;}
            p{
                @include flexCenter;
            }
        }
    }
}
.custom__select .custom__select__arrow {
    @include position(absolute,10px,10px,auto,auto);
}
.custom__select__list {
    display: none;
    @include position(absolute,37px,auto,auto,auto);
    width: 190px;
    padding: 0 10px 8px;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    background: #272B30;
    box-shadow: inset 0px -1px 1px #181A1D;
    z-index: 1;
    @include boxsizing;
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        margin-top: 5px;
        box-shadow: inset 3px 3px 5px rgba(213, 230, 245, 0.1), inset 2px 2px 2px rgba(26, 32, 38, 0.4);
        border-radius: 12px;
    }
    .custom__select__option {
        @include flexCenter;
        padding-top: 10px;
        .ico {
            display: inline-block;
            width: 25px;
        }
        .txt {
            padding-left: 9px;
            font-size: 20px;
            color: rgba(255, 255, 255, 0.5);
        }
        &:hover{
            .txt {color: #fff;}
        } 
    }
}
.custom__select__list.toggle {display: block;}

.cont__tit__box{
  @include dspCollection(flex,space-between,center);
}
.cont__box {
    height: 100%;
    .bottom__txt {
        @include flexCenter;
        padding: 5px 0 16px;
        @include font(normal,normal,13px,rgba(255, 255, 255, 0.5),18px);
        span{
            font-family: var(--font1);
            letter-spacing: -0.08px;
        }
    }
    .cont__number {
        @include boxsizing;
        .txt {
            font-family: var(--font1);
            @include font(normal,normal,50px,rgba(255, 255, 255, 0.5),34px);
            letter-spacing: 0.87px;
            .number__input {
                margin: 0;
                border: 0;
                background-color: transparent;
                font-family: 'DS-DigitalB';
                @include font(normal,bold,38px,#17a2b1,34px);
                text-align: right;
                letter-spacing: 0.87px;
            }
            input::placeholder{color: #17a2b1;}
        }
        .bottom__txt{text-align: right;}
    }
    .cont__index__box{
        @include dspCollection(flex,space-between,center);
        padding: 9px 16px 0;
        @include boxsizing;
        + .cont__index__box{padding-top: 0;} 
    }
    .btm__txt{padding-left: 60px;;}
}
  
input[type="number"] {
    &::-webkit-outer-spin-button, 
    &::-webkit-inner-spin-button {margin: 0;-webkit-appearance: none;}
}
  
.select__three__area {
    display: flex;
    .index__area__inneer {
        @include dspCollection(flex,center,center);
        flex-direction: column;
        height: 70px;
        text-align: center;
        + .index__area__inneer {margin-left: 24px;}
        .num, .txt {
            @include font(normal,normal,38px,#fff,26px);
            font-family: Pocket Calculator OT;
        }
        .num.led__font {
            font-family: LED Board-7;
            font-size: 22px;
        }
        .txt {
            padding-top: 8px;
            font-size: 22px;
            line-height: 22px;
            &.led__font {
                font-family: LED Board-7;
                font-size: 22px;
            }
        }
    }
}
  
.section__wallet {
    width: 255px;
    justify-content: center;
    margin: 40px auto 0;
}

.index__section{
    @include dspCollection(flex,space-between,flex-start);
    position: relative;
    margin-top: 20px;
    .index__box{
        @include border-radius(12px);        
        background: #202226;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.75), inset -2px -2px 2px #4E5154;
    }
    .index__area{
        width: 420px;
    }
}
.arrow__hr__box{
    // @include midCenter;
    @include circle(28px,28px);
    position: static;
    margin: -10px auto;
    // margin-top: 15px;
    // border: 1px solid #000000;
    background: linear-gradient(185deg, #141515 14.75%, #262a2d 84.81%);
    box-shadow: 4px 6px 20px rgba(0,0,0,0.15%); 
    text-align: center;
    @include boxsizing;
    .arrow__hr{
        position: relative;
        transform: none;
        left: 0%;
        top: 30%;
    }
    .ico_arrow{display: flex; justify-content: center; align-items: center; width: 8px;margin: 0px auto;}
    
    // .arrow__hr{
    //     // padding-top: 7px;
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     .ico_arrow{
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         width: 10px;
    //     }
    // }
}

.btn__max{
    @include border-radius(100px);
    display: inline-block;
    width: 48px;
    height: 22px;
    margin-left: 10px;
    padding: 2px;
    background: rgba(54, 158, 73, 0.15);
    border: 1px solid var(--blue);
    font-family: var(--font1);
    @include font(normal,normal,13px,#369E49,18px);
    &:hover,&.active,&:focus{
        background: linear-gradient(320.66deg, #29869f 14.75%, #00CAFF 84.81%), linear-gradient(320.66deg, #0190aa 14.75%, #00a2d3 84.81%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        color: #202024;
    }
}

.index__route{
    @include dspFlexCenter;
    width: 465px;
    margin: 24px auto 0;
    .tit{
        padding-right: 15px;
        font-family: var(--font2);
        @include font(normal,normal,15px,$whiteTransparent,24px);
        letter-spacing: -0.41px;
    }
    .route__list__box{
        @include flexCenter;
        .route__item{
            @include flexCenter;
            span.finish{
                color: var(--white) !important;
            }
            .route__stroke__arrow{margin: 0 15px;}
            .route__txt{
                padding-left: 10px;
                font-family: var(--font2);
                color: rgba(167, 172, 179, 0.16);
                @include font(normal,bold,15px,null,24px);
                letter-spacing: 0.75px;
                &.on{color: var(--white);}
            }
        }
        
    }
    .step__item {
        @include dspFlexCenter;
        @include circle(20px,20px);
        background: rgba(168,172,179,.05);
        border: 1px solid rgba(167, 172, 179, 0.16);
        @include boxsizing;
        .step__txt{
            font-family: var(--font1);
            @include font(normal,normal,15px,rgba(167, 172, 179, 0.16),20px);
            letter-spacing: -0.24px;
        }
        &.on{
            background-color: var(--white);
            border-color: var(--white);
            .step__txt{
                color: #202024;
            }
        }
        &.finish{
            background: #fff url(../../media/icons/ico-check.svg) no-repeat;
            background-position: center;
            font-size: 0;
            .step__txt{
                font-size: 0;
            }
        }
        
    }
}

.index__step {
    @include dspFlexCenter;
    padding-top: 30px;
    .step__item {
        @include dspFlexCenter;
        @include circle(32px,32px);
        background: rgba(168, 172, 179, 0.05);
        border: 1px solid #A8ACB3;
        @include boxsizing;
        .step__txt{
            font-family: var(--font1);
            @include font(normal,normal,15px,#A8ACB3,20px);
            letter-spacing: -0.24px;
        }
        &.on{
            background-color: #fff;
            border-color: #fff;
            .step__txt{
                color: #202024;
            }
        }
        &.finish{
            background: #fff url(../../media/icons/ico-check.svg) no-repeat;
            background-position: center;
            font-size: 0;
            .step__txt{
                font-size: 0;
            }
        }
        
    }
    .step__line{
        width: 64px;
        height: 1px;
        margin: 0 15px;
        background: #A8ACB3;
        &.finish__line{background: #fff;}
    }
}

.index__exchage__box{
    .exchage__tit,.exchage__txt{
        font-family: var(--font1);
        @include font(normal, normal, 13px, #A8ACB3, 18px);
        letter-spacing: -0.08px;
    }
    .exchage__list{
        @include dspFlexCenter;
        padding-left: 18px;
        .exchage__txt{
            // color:#fff;
            color:#A8ACB3 !important;
            span + span {padding-left: 4px;}
        }
        .line{
            width: 1px;
            height: 8px;
            margin: 0 8px;
            background: #fff;
        }
        .last__alignment__item{padding-top:0;}
    }
    &--web{
        @include dspFlexCenter;
        // margin-top: 24px;
    }
    &--mob{display: none;}
}
.step__btn{
    margin-top: 30px;
    .btn__ico{display:none;margin-right: 8px;}
    &.active .btn__ico{display: inline-block;}
}

/* mobile stylings =================================== */
.mob__content__table{display: none;margin-top: 32px;}
.MuiAccordionSummary-root{
    min-height: auto!important;
    padding: 0!important;
}
.MuiAccordionSummary-root.Mui-expanded{min-height: auto!important;}
.MuiAccordionSummary-content{
    justify-content: space-between;
    margin: 0!important;
    .expand__ico{
        filter: opacity(0.5);
    }
    &.Mui-expanded{
        margin: 0!important;
        .expand__ico{transform: rotate(180deg);}
    }
}
.MuiTypography-body1{
    font-family: var(--font2)!important;
    @include font(normal,bold!important,15px!important,rgba(255, 255, 255, 0.5)!important,24px!important);
    letter-spacing: -0.41px!important;
}
.MuiAccordionDetails-root{padding: 8px 0 0!important;}
.information__table{
    width: 100%;
    .information__item{
        @include dspCollection(flex,space-between,baseline);
        padding-right: 25px;
        + .information__item{padding-top: 5px;}
        .title{
            font-family: var(--font1);
            @include font(normal,normal,15px,rgba(255, 255, 255, 0.5),22px);
            letter-spacing: -0.41px;
        }
        .number{
            font-family: var(--font1);
            @include font(normal,bold,15px,#fff,22px);
            letter-spacing: -0.41px;
        }
        .sub__number{
            font-family: var(--font1);
            @include font(normal,normal,13px,rgba(255, 255, 255, 0.5),18px);
            text-align: right;
            letter-spacing: -0.08px;
        }
        .ico__img{padding-right: 4px;}
    }
}

/* media stylings =================================== */
@include breakPoint(1000px) {
    .mob__content__table{display: block;}
    .web__content__table,.index__route .tit{display: none;}
    .toggle__head{width: 192px;}
    .toggle__menu{
        height: 40px;
        .toggle__menu__tit{height: 32px;}
    }
    .index__section{
        display: block;
        .index__area{
            width: 100%;
            margin-bottom: 12px;
        }
    }
    .arrow__hr__box{
        position: static;
        margin: 10px auto;
        height: 28px;
        width: 28px;
        transform: none;
        border: none;
        z-index: 505;
        .arrow__hr{
            position: relative;
            transform: none;
            left: 0%;
            top: 30%;}
        .ico_arrow{
            margin: 6px auto;
            img{
                width: 8px;
            }
        }
    }
    .index__route{
        width: 100%;
        margin-top: 20px;
        .route__list__box{
            color: var(--coloured-fade-white) !important;
            .route__item{
                .route__txt{padding-left: 5px;font-size: 12px;}
                .route__stroke__arrow{margin: 6px 5px 0 5px;}
            }
        }
    }
    .index__exchage__box{
        &--web{display: none;}
        &--mob{display: block;}
    }
}
