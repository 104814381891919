@charset "UTF-8";

/* *******************************************************
    filename : common.css
    description : reset / font / common CSS
******************************************************** */

// import
@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import './mixin.scss';

// default
* {margin:0;padding:0; letter-spacing:-0.025em;}

html {width:100%; height:100%;}
a{text-decoration:none;}// color: #333;
a, a:hover {text-decoration:none;}
a[href*="callto"]:hover, a[href*="mailto"]:hover {text-decoration:underline;}
textarea {width:100%; word-wrap:word; word-break:break-word; resize:none;}/* resize:none; */
img {vertical-align:middle; max-width: 100%;}
img, fieldset, iframe {border:none; outline:none;}
legend {display:none;}
input, select, button {display:block; width:100%; height: 100%; font-size:inherit; box-sizing: inherit;}/* crossbrowsing code */
select, button, textarea {border:none; outline:none; background:transparent; appearance:none; -moz-appearance:none; -webkit-appearance:none;}/* custom style code */

input:focus, select:focus, button:focus, textarea:focus {outline:none;}/* focus animation banding */
input[type="button"], input[type="checkbox"], input[type="radio"], button, .btn {cursor:pointer;} /* button cursor sync */

button *{position:relative;}
h1,h2,h3,h4,h5,h6,strong,b {font-weight:normal;}/* h tag font style reset code */
i, em, address {font-style:normal;}
table, tbody, thead, tr, td, th {border-collapse:collapse; border-spacing:0;}
table {width:100%;}
ul,li{list-style: none;}
.hidden,.hide{position: absolute; top: 0; left: -9999em;}

label, input, select, textarea, button {-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
div,span,p,a,ul,li,em,h1,h2,h3,h4,h5,h6,input, select, textarea, button, a, label {-webkit-tap-highlight-color:transparent;}

/* consisly code */
.disappear {width:0; height:0; display:none; visibility:hidden; opacity:0; line-height:0; font-size:0; margin:0;padding:0;position:relative;z-index:-100;}
.blocked {display:block; width:100%; height:100%;}
.limit_text {text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
.remove_text {text-indent:-9999;}
.clearfix {zoom:1;}
.clearfix:before, .clearfix:after {content: "";display: table;clear: both;}

/*mobile code*/
.smoothScroll {-webkit-overflow-scrolling:touch;}
.contentsFrame {box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box;}
.modal select { appearance:menulist; -moz-appearance:menulist; -webkit-appearance:menulist;}

/* placeholder */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */ color:#999; }
::-moz-placeholder { /* Firefox 19+ */ color:#999; }
:-ms-input-placeholder { /* IE 10+ */ color:#999; }
:-moz-placeholder { /* Firefox 18- */ color:#999; }
:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */ opacity:0; }
:focus::-moz-placeholder { /* Firefox 19+ */ opacity:0; }
:focus:-ms-input-placeholder { /* IE 10+ */ opacity:0; }
:focus:-moz-placeholder { /* Firefox 18- */ opacity:0; }

/* *******************************
    description : font.css
******************************** */
/* led font */
@font-face {
    font-family: 'DS-Digital';
    font-style: normal;
    font-weight: 300;
    src:local('DS-DIGI'),
        url('../fonts/DS-DIGI.TTF') format('truetype');
}
@font-face {
    font-family: 'DS-Digital';
    font-style: normal;
    font-weight: 500;
    src:local('DS-DIGIB'),
        url('../fonts/DS-DIGIB.TTF') format('truetype');
}

@font-face {
    font-family: 'DigitalNumbers';
    font-style: normal;
    font-weight: 300;
    src:local('DigitalNumbers'),
        url('../fonts/DigitalNumbers-Regular.ttf') format('truetype');
}


/* ==================== html, body stylings ====================== */
body {
    @include fullBox;
    background-color: $masterBgColor; 
    font-family: var(--font2); 
    font-size: 15px; 
    color: $masterWiteColor;
    word-spacing:-0.025em;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-text-size-adjust:none;
    word-break:break-word;
    -ms-word-break:break-word;
}

/* variables =================================== */
#wrap{position: relative;}
#wrap,
#wrap .container{
    @include boxsizing();
    @include fullBox;
    display: flex; 
    flex-direction: column;
    min-height: 100vh; 
}
#main{flex:1;}
.inner{
    @include fullBox;
    display: block; 
    position: relative; 
    max-width: 1380px; 
    margin: 0 auto; 
    padding: 50px 15px 65px; 
    @include boxsizing();
}

.inner__sm{max-width: 908px; margin: auto;}
.inner__md{max-width: 975px; margin: auto;}
.inner__lg{max-width: 1104px; margin: auto;}
.inner__xl{max-width: 1140px; margin: auto;}
.container{
    @include fullBox; 
    margin: 0 auto; 
    padding: 0 30px; 
    @include boxsizing;
}

.pointRedColor{color: $redColor!important;}
.pointGreenColor{color: $pointGreenColor!important;}

.mb-0{margin-bottom: 0!important;}
.text-right{text-align: right!important;}

/* title =================================== */
.tit__md{
    font-family: var(--font1);
    @include font(normal,normal,1.9rem,#fff,18px);
    letter-spacing: -0.08px;
}
.font__bold{font-weight: bold!important;}

/* ==================== alignment stylings ====================== */
.alignment__list{
    .alignment__list__item{
        padding-top: 16px;
        @include dspCollection(flex, flex-start, center);
        &.last__alignment__item{padding-top:0;}
    }
    .alignment__box{
      @include dspCollection(flex, initial, center);
        .alignment__txt{
            padding: 0 10px;
            font-family: var(--font1);
            @include font(normal, normal, 2.2rem, $masterWiteColor, 18px);
            letter-spacing: -0.08px;
            .num{
                padding-right: 6px;
            }
        }
        .alignment__tit{
            font-family: var(--font1);
            @include font(normal, normal, 2.2rem, var(--fade-white), 18px);
            letter-spacing: -0.08px;
        }
        .alignment__ico{
            display: inline-block;
            width:21px;
            height: 24px;
        }
        .fee__txt{padding-left: 0;}
    }
    .alignment__line{
        width: 100%;
        height: 1px;
        margin: 24px auto;
        background: #A8ACB3;
    }
    .link{
        display: flex;
        align-items: center;
        padding-top: 4px;
        font-family: var(--font1);
        @include font(normal,normal,13px,#A8ACB3,18px);
        line-height: 18px;
        img{margin-right: 4px;}
    }
}

/* ==================== content box stylings ====================== */
  
.content__box {
    margin-top: 48px;
    padding: 25px;
    @include border-radius(24px);
    background: linear-gradient(180deg, #353A40 0%, #16171B 100%);
    @include boxsizing;
}
.index__section .index__area.index__twoBox{
    width: 430px;
    + .index__twoBox{ margin-left: 24px}
}
.index__section .index__area.index__fullBox{width: 100%;}

/* ==================== settings icon stylings ====================== */

.cont__settings {
    // color: rgba(54, 158, 73, 0.15);
    // color: #00ccff2d;
    display: flex;
    .btn__settings {
        padding:2px;
        @include circle(44px,44px);
        @include boxsizing;
        + .btn__settings {margin-left: 12px;}
        &:hover{
            img{
                transition: filter 0.3s ease-in-out;
                filter: invert(56%) sepia(11%) saturate(100%) hue-rotate(79deg) brightness(100%) contrast(88%);
            }
        }
    }
    .btn__gwei{
        width: 162px;
        border-radius: 50px;
        background: linear-gradient(180deg, #252a2e 14.75%, #1C1F22 84.81%);
        box-shadow: 18px 6px 32px rgba(2, 3, 3, 0.55), -6px -10px 28px rgba(232, 237, 243, 0.15);
        span{
            padding-left: 12px;
            font-family: var(--font1);
            @include font(normal,bold,15px,#A8ACB3,24px);
            letter-spacing: -0.41px;
        }
    }

    .btn__setting{
        border-radius: 50px;
        background: linear-gradient(180deg, #252a2e 14.75%, #1C1F22 84.81%);
        box-shadow: 18px 6px 32px rgba(2, 3, 3, 0.55), -6px -10px 28px rgba(232, 237, 243, 0.15);
    }
}

.flexBox{
    display: grid;
    align-items: end;
    grid-template-columns: 420px 1fr;
    @media screen and (max-width: 1000px) {
        display: block;
    }
}

.indexInnerBox{
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 20px;
    width: 100%;
    box-sizing: border-box;
    @media screen and (max-width: 1000px) {
        padding: 0;
        margin: 2.5rem auto;
    }
    @media screen and (max-width: 420px) {
        flex-direction: column;
        text-align: center;
    }
}

/* ==================== table custom stylings ====================== */
.content__index__table {
    margin: 48px 125px 48px 150px;
    padding: 0 20px;
    .content__index__head, 
    .content__index__trow, 
    .content__index__foot {
        display: grid;
        grid-template-columns: 140px repeat(2, 1fr) 150px;
        height: 55px;
    }
    .content__index__head{
        height: 24px;
        margin-bottom: 16px;
    }
    .content__index__head p, 
    .content__index__foot p, 
    .content__index__head span, 
    .content__index__foot span {
        font-family: var(--font2);
        @include font(normal,bold,15px,rgba(255, 255, 255, 0.5),24px);
        letter-spacing: -0.41px;
        text-align: center;
    }
    .content__index__trow {
        p.totla__txt{
            @include flexCenter;
            justify-content: flex-end;
            img{margin-right: 6px;}
        }
        p, span {
            @include font(normal,bold,18px,#fff,24px);
            font-family: var(--font1);
            letter-spacing: -0.41px;
            text-align: center;
        }
        .num {
            font-weight: normal;
            font-size: 15px;
            color: #fff;
        }
        .font__sm{
            font-size: 13px; 
            color:rgba(255, 255, 255, 0.5);
            text-align: right;
        }
    }
}

/* ==================== material-ui table stylings ====================== */
.MuiPaper-elevation1{box-shadow: none!important;}
.MuiTable-root{border-collapse: separate!important; border-spacing: 0 12px!important;}
.MuiPaper-root{
    background-color: transparent!important;
    .MuiTableRow-head{
        background-color: transparent!important;
        box-shadow: none!important;
    }
    .MuiTableCell-head{
        font-family: var(--font2) !important;
    }
    .MuiTableCell-root{
        border-bottom: 0;
        font-family: var(--font1);
        @include font(normal, 600!important, 15px, rgba(255, 255, 255, 0.5) , 24px);
        letter-spacing: -0.41px;
        padding: 16px 8px;
    }
    .MuiTableRow-root{
        // box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.75), inset -2px -2px 2px #4E5154;
        background: var(--nearBlack);
        border: 1px solid var(--nearBlackBorder);
        border-radius: 1.4rem;
    }
    .MuiTableBody-root{
        .MuiTableCell-root{
            font-size: 16px;
            color: #fff;
            font-weight: 500 !important;
        }
        .none__cell{
            font-family: var(--font2);
            @include font(normal,normal,15px,#A8ACB3,24px);
            letter-spacing: -0.41px;
        }
        .point__txt{
            font-size: 16px;
            color: var(--blue);
        }
        .address__txt{
            display: inline-block;
            width: 159px;
            padding-left: 8px;
            font-size: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }
    .MuiTableFoot-root{
        .MuiTableRow-root{
            box-shadow: none;
        }
    }
    
}

.head__logo__box{
    @include dspCollection(flex, center, center);
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
    border-radius: 11px;
    background: #272B30;
    box-shadow: inset 0px -1px 1px #181A1D;     
}

/* ==================== form stylings ====================== */
/* input stylings =================================== */
.form-input{
    width: 302px;
    padding: 17px 32px;
    border: none;
    border-radius: 50px;
    background: #1F1F1F;
    font-family: var(--font1);
    @include font(normal,normal,15px,#fff,150%);
    letter-spacing: -0.24px;
    box-shadow: none;
    @include boxsizing;
}
.form-input::placeholder{color: #fff;}

/* mobile stylings =================================== */
.mob__show__txt{display: none;}

/* media stylings =================================== */
@include breakPoint(1150px) {
    .inner__lg{max-width: 100%}
}
@include breakPoint(1000px) {
    .mob__show__txt{display: block;}
    .mob__none__txt{display: none!important;}
    .inner{padding:32px 15px;}
    .inner__sm{max-width: 100%;}
    
    .content__box{margin-top: 32px;padding: 16px}
    .index__section .index__area.index__twoBox{
        width: 100%;
        + .index__twoBox{ margin-left: 0}
    }
    .transactionInput{
        // font-size: 32px !important;
    }
}

@include breakPoint(420px) {
    .btn__settings{
        // img {display: none !important;}
        .number{padding-left: 0px !important}
    }
    .btn{padding: 5px !important;}
    .indexInnerBox .tit{margin-bottom: 10px;}
    .mob__index__table .heading__box .sub__heading{
        padding-right: 5px !important;
        margin-left: 5px !important;
        font-size: 14px;
    }
    .mob__index__table .MuiPaper-root {
        padding: 10px !important;
    }
}