@charset "utf-8"; 

@import '../../media/css/common.scss';

.pop__close{
  @include circle(48px,48px);
  @include border-radius(30px);
  // old background: linear-gradient(318.21deg, #2C3036 14.05%, #31343C 85.29%);
  background: linear-gradient(180deg, #252a2e 14.75%, #1C1F22 84.81%);
  box-shadow: 18px 6px 32px rgba(2, 3, 3, 0.55), -6px -10px 28px rgba(232, 237, 243, 0.15);
  // old transform: rotate(180deg);
  // old box-shadow: 1px 1px 6px #3e3e3e;
  position: relative;
  top: -5px;
  .close{
      @include dspFlexCenter;
      @include circle(48px,48px);
      @include border-radius(30px);
      //old background: linear-gradient(320.66deg, #2F353A 14.75%, #1C1F22 84.81%);
      //old box-shadow: 1px 1px 9px #2b2a2a;      
  }
  &:hover .close{
      transform: rotate(180deg);
      transition: transform 1s ease-in-out;
  }
  &:active{
    box-shadow: "0 0 0 8px transparent",
  }
}

@include breakPoint(1000px){
  .wallet-connect-modal {
    height: 55rem !important;
  }
}
