@charset "utf-8"; 

/* *******************************************************
    filename : footer.css
    description : footer layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* footer */
.footer {
    padding: 0 0 25px;
    @include boxsizing;
    .foot__line{
        display: block;
        width: 100%;
        height: 1px;
        margin-bottom: 20px;
        background: #202226;
        box-shadow: inset 0 4px 4px rgb(0,0,0,0.75), inset -2px -2px 2px #4e5154;
    }
    .footer__inner {
        @include dspCollection(flex,space-between,center);
        max-width: 950px;
        width: $size100;
        margin: auto;
        padding: 0 40px;
        @include boxsizing;
    }
    .foot__snn__link {
        margin-top: 5px;
        .sns__link + .sns__link {margin-left: 26px;}
        .sns__link{
            display: inline-block;
            width: 20px;
            &:hover{
                @include animation(shake 0.6s infinite alternate)
            }
        }
    }
    .foot__nav {
        display: flex;
        .foot__nav__item + .foot__nav__item {padding-left: 56px;}
        .foot__nav__link {
            @include font(normal, normal, 13px, rgba(255, 255, 255, 0.5), 18px);
            font-family: Work Sans;
            text-align: center;
            letter-spacing: -0.08px;
            &:hover {
                color: #fff;
                transition: color .3s;
            }
        }
    }
  
    .copyright {
        font-family: var(--font1);
        @include font(normal, normal, 13px, rgba(255, 255, 255, 0.5), 18px);
        letter-spacing: -0.08px;
    }
}

@include keyframes(shake){
    0% {transform: rotateZ(10deg);transform: rotateZ(10deg);}
    100% {transform: rotateZ(-10deg);transform: rotateZ(-10deg);}
}

/* mobile stylings =================================== */
.mob__foot__nav{
    .foot__nav__item{padding-top: 16px;}
    .foot__nav__link{
        font-family: Work Sans;
        @include font(normal,normal,13px,rgba(255, 255, 255, 0.5),18px);
        letter-spacing: -0.08px;
        transition: color .3s ease-in-out;
        &:hover,&.active{color: #fff;}
    }
}
.mob__sns__link{
    display: flex;
    width: 200px;
    .sns__link{
        display: inline-block;
        margin-right: 24px;
        &:hover{
            @include animation(shake 0.6s infinite alternate)
        }
    }
}
/* media stylings =================================== */
@include breakPoint(1440px) {
    .footer__inner{padding: 0 15px 0}
}
@include breakPoint(1060px) {
    .footer .foot__nav .foot__nav__item + .foot__nav__item{padding-left: 25px;}
}
@include breakPoint(1000px){
    .footer{
        .footer__inner{@include dspFlexCenter;}
        .foot__snn__link,.foot__nav{display: none;}
    }
}
