@charset "utf-8"; 

// import
@import '../../media/css/common.scss';

.index__route{
    @include dspFlexCenter;
    width: 465px;
    margin: 20px auto 0;
    .tit{
        padding-right: 15px;
        font-family: Space Mono;
        @include font(normal,normal,15px,$whiteTransparent,24px);
        letter-spacing: -0.41px;
    }
    .route__list__box{
        @include flexCenter;
        .route__item{
            @include flexCenter;
            .route__stroke__arrow{margin: 0 15px;}
            .route__txt{
                padding-left: 10px;
                font-family: Space Mono;
                @include font(normal,normal,15px, '',24px); //$masterWiteColor
                letter-spacing: 0.75px;
            }
        }
        
    }
    .step__item {
        @include dspFlexCenter;
        @include circle(20px,20px);
        background: rgba(168, 172, 179, 0.05);
        border: 1px solid #A8ACB3;
        @include boxsizing;
        .step__txt{
            font-family: Work Sans;
            @include font(normal,normal,15px,#A8ACB3,20px);
            letter-spacing: -0.24px;
        }
        &.on{
            background-color: #fff;
            border-color: #fff;
            .step__txt{
                color: #202024;
            }
        }
        &.finish{
            background: #fff url(../../media/icons/ico-check.svg) no-repeat;
            background-position: center;
            font-size: 0;
            .step__txt{
                font-size: 0;
            }
        }
        
    }
}

@include breakPoint(1000px){
    .route__stroke__arrow{
        position: relative;
        top: -3px;
    }
}