@charset "utf-8"; 

/* *******************************************************
    filename : claim.css
    description : claim layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* ==================== claim stylings ====================== */
.claim{
    .details { 
        font-family: Work Sans;
        // @include font(normal,bold,22px,#fff,28px);
        letter-spacing: 0.8px;
        h1{font-size: 2.7em;}
        h2{color: var(--govBlue)}
        hr{font-size: 10px; color: var(--fade-white); margin: 1rem auto;}
        article{font-size: 1.3em; line-height: 1.4em;}
        span{color: var(--fade-white)}
        
    }
    .action-button{
        margin: 3rem auto .5rem auto;
        width: 100%;
        cursor: pointer;
        .buy-gbrc{
            font-family: Work Sans !important;
            font-size: 1.1em !important; line-height: 1.4em !important;
            margin-bottom: 1.4rem;
        }
    }
    .tipsyBanner {
        height: auto;
        width: 100%;
        margin-right: 6rem;
    }
    video{
        position: relative;
        right: 3rem;
    }
    .claim-disclaimer .MuiSvgIcon-root{
        font-size: 40px;
    }
    
}

/* media stylings =================================== */
@include breakPoint(1090px) {
    .claim{
        margin-top: 5rem;
        padding: 3rem 4rem;
        .MuiGrid-root{
            margin: 0 auto;
        }
        .details { 
            margin-top: 3rem;
        }
        video{
            right: 1rem;
        }
    }
}

@include breakPoint(450px) {
    .claim{
        margin-top: 4rem;
        .details { 
            margin-top: 3rem;
            h1{font-size: 2em;}
        }
        video{right: 0}

    }
}
