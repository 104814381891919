@import "./media/css/fonts.css";

:root {
  font-size: 10px;
  font-family: var(--font1);
  position: relative;
  background-color: var(--almost-black);

  /* COLORS */

  --fade-white: rgba(255, 255, 255, 0.5);
  --coloured-fade-white: rgb(139, 142, 184);
  --x-fade-white: rgba(255, 255, 255, 0.1);
  --xx-fade-white: rgba(167,172,179,.16);
  --almost-white: white;
  --white: white;

  --grey: #3a3939;
  --lightgrey: #606060;
  --nearBlack: rgb(22,22,22);
  --nearBlackBorder: rgb(10,10,10);
  --almost-black: #1B1B1B; /*#121212; */ /*#22262C*/
  --almost-black-2: rgb(33, 33, 33); /*#; */ /*#rgb(35, 35, 35)*/
  --dark-gradient-bg: linear-gradient(210.55deg, #292D32 -3.02%, #1F2123 93.08%);
  --dark-gradient-bg2: linear-gradient(180deg,#353a40,#16171b);

  --fade-black: rgba(0, 0, 0, 0.5);
  --dark-grey: #272B30;
  --light-black: #202024;
  --black-overlay: rgba(0, 0, 0, 0.678);
  --black: black;
  --blue: #00caff;
  --govBlue: #007ef7; 
  /* #0054e5; */
  --green: #01AA68;
  --green2: #369E49;
  --green-dark: rgb(29, 142, 72, 0.3);
  --green-dark-full: rgb(25, 147, 69, 1);
  --green-gradient-bg3: linear-gradient(320.66deg,#369e49 14.75%,#48dc63 84.81%),linear-gradient(320.66deg,#01aa68 14.75%,#00d381 84.81%);
  --green-gradient-bg2: linear-gradient(320.66deg, #01AA68 14.75%, #00D381 84.81%);
  --green-gradient-bg: linear-gradient(320.66deg, #369E49 14.75%, #48DC63 84.81%);
  --blue-gradient-bg: linear-gradient(320.66deg, #29869f 14.75%, #00CAFF 84.81%), linear-gradient(320.66deg, #0190aa 14.75%, #00a2d3 84.81%);
  

  --skyBlue: #17a2b1;
  --red: #9E3636;
  --deep-red: #EF0808;
  --error: #dd6579;
  --warning: #faa613;
  --slider-track-gradient: linear-gradient(
    90deg,
    var(--almost-white) 0%,
    var(--green-dark-full) 50%,
    var(--green-dark-full) 100%
  );
  
  /* FONTS */

  --font1: 'Inter', sans-serif;
  --font2: 'Inter', sans-serif;
  --font3: 'Inter', sans-serif;
  --font4: 'Inter', sans-serif;
  /* --font1: 'Roboto Mono', monospace;
  --font2: "Space Mono", "Nunito";  
  --font3: "DS-Digital", "LED Board-7", "Work Sans";
  --font4: "DigitalNumbers", "DS-Digital", "LED Board-7", "Work Sans"; */

  /* BREAKPOINTS */
  --tabletBreakpoint: 1000px;
  --smallLaptopBreakpoint: 1441px;
  --largeScreenBreakpoint: 1919px;

  /* TRANSITIONS */

  --transition: all 200ms ease-in-out;
  --Ytransform: translateY(-50%);
  --transition2: transform 1s ease-in-out;

  --pulse-animation: pulseGov 1s;
  --pulse-animation-blue: pulseBlue 1s;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button {
  border: none;
  cursor: pointer;
  background: none;
  user-select: none;
  color: inherit;
  font: inherit;
}
button:active {
  outline: none;
}
button:focus {
  outline: 0;
}
button:disabled {
  cursor: not-allowed;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

body {
  scroll-behavior: smooth;
  hyphens: auto !important;
  word-break: break-word !important;
  /* white-space: nowrap; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, var(--font1), 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 420px;   */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

svg{
  height: 100%;
  width: 100%;
}

a.makeStyles-navButton-24 {
  font-family: var(--font2) !important;
  margin: auto 4rem;
}

.apexcharts-tooltip {
  background: var(--almost-white);
  color: var(--almost-black);
}



.mainCard {
  background: linear-gradient(180deg, #353A40 0%, #16171B 100%);
}

.mainCard btn {
  background: linear-gradient(320.66deg, #2F353A 14.75%, #1C1F22 84.81%);
  filter: blur(4px);
  border-radius: 30px;
  transform: rotate(180deg);
}

/* .greenButton {
  background: linear-gradient(320.66deg, #369E49 14.75%, #48DC63 84.81%);
  border-radius: 12px;  
} */

.top-cpmmetvt{
  background: linear-gradient(180deg, #353A40 0%, #16171B 100%);
  filter: blur(1px);
  border-radius: 100px;
  transform: rotate(-180deg);
}


.dark-impression {
  background: #202226;
  box-shadow: inset 0 4px 4px rgb(0 0 0 / 75%), inset -2px -2px 2px #4e5154;
}

.dark-impression-shallow {
  background: #202226;
  box-shadow: inset 0px 2px 2px rgba(10, 6, 6, 0.75), inset -1px -1px 1px #4E5154;
}

/** override switch style **/
.multi-switch-container {
  display: inline-block;
  height: auto;
  width: 100% !important;
  margin-top: 2px !important;
  padding-top: "-2px";
  background-color: transparent;
  position: relative;
  top: "-1px";
  border: none !important;
}

.multi-switch-content {
  z-index: 1 !important;
}

.square .multi-switch-container .multi-switch-handle{
  border-radius: 8px !important;
}

.multi-switch-handle{
  /* background: linear-gradient(
    -180deg,#29869f 14.75%,#00caff 84.81%),linear-gradient(
    -180deg,#0190aa 14.75%,#00a2d3 84.81%) !important; */
    background: rgb(60, 61, 82) !important;
  box-shadow: inset 0px -1px 1px #181a1d !important;  
  cursor: default;
  transition: all 0.3s ease-in-out !important;
}

.upColor{
  color: #00c805 !important;
}

.downColor{
  color: #E92318 !important;
}

.downColor.changeIcon{
  margin-top: 1px;
}

.changeIcon{
  margin-left: 5px;
  font-size: 16px;
  vertical-align: middle;
}

.changeIcon-mob{
  margin-left: 10px;
  font-size: 13px;
  position: relative;
  top: -1px;
}

.colouredFade{
  color: rgb(139, 142, 184) !important
}

/* ANIMATIONS */

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* BUTTON EVENT */
@keyframes pulseGov {
  0% {box-shadow: 0 0 0 0 var(--govBlue);}
}
@keyframes pulseBlue {
  0% {box-shadow: 0 0 0 0 #00CAFF;}
}

/* MEDIA QUERIES */

@media screen and (max-width: 999px) {
  :root {
    font-size: 7px !important;
  }
  .multi-switch-handle{
    background: rgb(60, 61, 82) !important;
    cursor: default;
    transition: all 0.3s ease-in-out !important;
  }
}
@media screen and (min-width: 600px) {
  :root {
    /* font-size: 6px !important; */
  }
}
@media screen and (min-width: 1000px) {
  :root {
    font-size: 7px;
  }
}
@media screen and (min-width: 1441px) {
  :root {
    font-size: 8px;
  }
}

@media screen and (min-width: 1921px) {
  :root {
    font-size: 9px;
  }
}
