@charset "utf-8"; 

/* *******************************************************
    filename : vault.css
    description : vault layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

.comming-soon{
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

@include breakPoint(480px) {
  .comming-soon{
    font-size: 15px !important;
  }
}