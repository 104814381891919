@charset "utf-8"; 

/* *******************************************************
    filename : analytics.css
    description : analytics layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* ==================== analytics stylings ====================== */
.analytics__area{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 20px;
  }
  .image-size{
    width: 22px;
    margin-right: 10px;
  }
  .image-size__after{
    width: 20px;
    &:last-child{
      height: 23px;
    }
  }
  .treasury-img{
    margin-left: 10px;
  }
  .treasury-img__eth{
    margin-left: 7px;
  }
  .ratio-info-icon{
    width: 19px;
    margin-left: 10px;
  }
  .ratio-right-text{
    flex: 40% !important;
  }

.analytics__cont__box{
    padding: 22px;
    // height: 500px;
    // background: #1e1e1e;
    border-radius: 24px;
    @include boxsizing;
    &.dark{
        background-color: #000000 !important;
    }
    .tit{
        display: block;
        padding-bottom: 8px;
        font-family: Space Mono;
        @include font(normal,bold,20px,var(--x-fade-white),24px);
        letter-spacing: 0.75px;
        cursor: pointer;
        margin-bottom: 15px;
        transition: all 0.3s ease-in-out;
    }
    .on{
        color: var(--govBlue);
        // padding: 10px;
        border-bottom: 2px solid var(--govBlue);
    }
    // .transparentShow{
    //     margin-left: 30px;
    //     height: 40px !important;
    //     padding: 10px 10px 0px 10px;
    //     margin-top: -4px;
    //     border-bottom: 1px solid var(--x-fade-white);
    //     font-family: var(--font1);
    //     @include font(normal,600,16px,var(--x-fade-white),18px);
    //     cursor: pointer;
    // }

    .sm__tit{
        display: block;
        padding-bottom: 8px;
        font-family: Work Sans;
        @include font(normal,600,13px,#fff,18px);
        letter-spacing: -0.08px;
    }
    .align__cont__box{
        @include dspCollection(flex,space-between,center);
        .txt{
            font-family: var(--font3);
            @include font(normal,bold,30px,#fff,34px);
            letter-spacing: 0.87px;
        }
        .tit{
            word-break: initial;
            margin-right: 1rem;
            position: relative;
            top: 4px;
        }
    }
    .number__txt{
        font-family: var(--font1);
        @include font(normal,500,30px,#fff,34px);
        letter-spacing: 0.87px;
    }
    .chg__cont__box{padding-top: 6px}
    .chg__txt{
        display: block;
        font-family: Work Sans;
        @include font(normal,normal,23px,rgba(255, 255, 255, 0.5),28px);
        letter-spacing: -0.08px;
     
        .dateText{
            font-size: 18px;
        }
        .downText{
            color: #E92318;
        }
        .upText{
            color: #369E49;
        }
        .downIcon{
            margin-left: 10px;
            font-size: 16px;
            color: #E92318;
            vertical-align: middle;
        }
        .upIcon{
            margin-left: 10px;
            font-size: 16px;
            color: #369E49;
            vertical-align: middle;
        }
    }
}
.analytics__price__box{
    width: 100%;
    background: #161616;
    +.analytics__price__box{margin-left: 20px;}
}
.dataTabButton{
    position: relative;
    width: 25px;
    font-family: var(--font1);
    font-size: 15px;
    color: #fff;
    &:hover{
        color: var(--govBlue);
        &:after{display: block;}
    }
    &:after{
        content: "";
        display: none;
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: 85%;
        height: 1px;
        margin: auto;
        background: var(--govBlue);
    }
}

.dataTabButton.active{
    color: var(--govBlue);
    &:after{display: block;}
}

.graph__area{
    // margin-top: 45px;
    .graph__box{
        width: 102.5%;
        // height: 400px;
        padding: 20px 24px;
        // background: #202226;
        // box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.75), inset -2px -2px 2px #4E5154;
        border-radius: 24px;
        @include boxsizing;
        p{color:rgba(0, 0, 0, 0.75)}
        &.normal{
            width: 100%;
        }
    }
}

/* mobile stylings =================================== */
.mob__analytics__area{display: none;}

/* media stylings =================================== */
@include breakPoint(1000px) {
    .web__analytics__area{display: none;}
    .mob__analytics__area{display: block;}
    .graph__area{
        margin-top: 0px;
        .graph__box{
            padding: 5px 0 10px;
            width: 103%;
            // background: #202226;
            // box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.75), inset -2px -2px 2px #4E5154;
            border-radius: 24px;
            @include boxsizing;
            p{color:rgba(0, 0, 0, 0.75)}
            &.normal{
                width: 100%;
            }
        }
    }
}

@include breakPoint(500px){
  .analytics-table-wrap{
    font-size: 13px !important;   
  }
  .image-size{
    width: 15px;
    margin-right: 5px;
  }
  .image-size__after{
    width: 14px;
    margin-left: 5px;
    &:last-child{
      height: 16px;
    }
  }
  .analytics-left-text{
    flex: 60% !important;
  }
  .treasury-img{
    margin-left: 1px 
  }
  .treasury-img__eth{
    margin-left: 0px;
  }
  .ratio-info-icon{
    width: 13px;
    margin-left:5px;
  }
}


@include breakPoint(450px) {
    .graph__area{
        .graph__box{
            width: 102%;
            &.normal{
                width: 100%;
            }
        }
    }
    .analytics__cont__box{
        padding: 15px;
        .number__txt{
            font-size: 22px;
            white-space: nowrap;
        }
        .tit{
            font-size: 15px;
        }
        .transparentShow{
            font-size: 12px;
            margin-left: auto;
            border-color: var(--fade-white);
            color: var(--fade-white);
        }
        .chg__cont__box{
            display: none;
        }
    }
}

#dexscreener-embed{
    position:relative;
    width:100%;
    top: -35px;
    height: 350px !important;
    overflow: hidden;
}

// @media(min-width:1400px){
//     #dexscreener-embed{padding-bottom:65%;}
// }

#dexscreener-embed iframe{
    position:relative;width:100%;height:350px !important;top:38px !important;left:0;border:0;
}

#dexscreener-embed iframe>a {
    display: none !important;
}