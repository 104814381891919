@charset "utf-8"; 

/* *******************************************************
    filename : header.css
    description : header layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* content stylings =================================== */
/* header */
.header{
    @include dspCollection(flex,space-between,center);
    max-width: 1380px; 
    width: $size100; 
    margin: auto; 
    padding-top: 24px;
    @include boxsizing;
    .header__logo__link{
        cursor: pointer;
        &--img{display: inline-block;width: 30px;}   
        &--txt{
            padding-left: 10px;
            font-family: 'WorkSansB';
            @include font(normal,bold,18px,#fff,24px);
            letter-spacing: 0.75px;
        }     
    }
}

/* gnb */
.gnb__area{
    // @include position(absolute,auto,auto,auto,50%);
    // transform: translateX(-50%);
    position: relative;
    left: 7rem;
    .gnb {
        display: flex;
        .gnb__item {
            padding-left: 56px;
            // + .gnb__item {padding-left: 56px;}
            &.on .gnb__item__link {color: #fff; transition: color .3s;}
        }
        .gnb__item__link {
            @include font(normal, bold, 20px, rgba(255, 255, 255, 0.5), 24px);
            letter-spacing: 0.75px;
            &:hover, &.selected {color: #fff;transition: color .3s;}
        }
    }
}

/* header select */

.select__area {
    @include flexCenter;
    position: relative;
    .select__item {
        @include boxsizing;
        @include border-radius(100px);
        @include transition(all .3s ease-in-out);
        display: flex;
        padding: 2px;
        background: linear-gradient(#353A40 100%, #16171B 100%);
        &.secon__select__item {
            @include circle(50px,50px);
            margin-left: 15px;
            cursor: pointer;
        }
        .txt {
            @include font(normal, bold, 15px, #fff, 24px);
            display: inline-block;
            margin-left: 12px;
            letter-spacing: -0.41px;
        }
        .tr__menu {
            @include dspFlexCenter();
            @include border-radius(100px);
            padding: 8px 24px;
            border: 1px solid #000;
            background: linear-gradient(0deg, #353A40 0%, #16171B 100%);
            &.svg__wrap {
                @include circle(50px,43px);
                padding: 0;
            }
            &:link, &:visited {
                @include border-radius(100px);
                background: rgba(54, 158, 73, 0.1);
            }
            &:hover{
                @include animation(pulseHov 1s);
                box-shadow: 0 0 0 6px rgba(#fff,0);
                img{
                filter: invert(34%) sepia(49%) saturate(4664%) hue-rotate(194deg) brightness(99%) contrast(104%);
                }
                .txt{color: var(--govBlue)}
                svg path{stroke: var(--govBlue);}
            }
        }
    }
}

@include keyframes(gelatine){
    // 0%{transform: scale(1, 1);}
    // 25% {transform: scale(0.9, 1.1);}
    // 50% {transform: scale(1.1, 0.9);}
    // 75% {transform: scale(0.95, 1.05);}
}

@include keyframes(pulseHov){ 0% {box-shadow: 0 0 0 0 var(--govBlue);} }

.sub__select__area {
    @include boxsizing;
    @include border-radius(12px);
    @include position(absolute, 70px, 0, auto,auto);
    display: none;
    width: 235px;
    padding: 18px 19px;
    background: linear-gradient(180deg, #353A40 0%, #16171B 100%);
    box-shadow: inset 0px -1px 1px #181A1D;
    z-index: 100;
    .sub__select__item {
        display: flex;
        + .sub__select__item {padding-top: 16px;}
        .svg__wrap{margin-right: 16px;}
        .sub__txt {    
            @include font(normal, normal, 15px, #fff, 20px);
            position: relative;
            letter-spacing: -0.24px;
            transition: 0.5s;
            &::after {
                @include position(absolute,100%,auto,auto,0);
                content: "";
                width: 100%;
                height: 2px;
                background: var(--govBlue);//$greenColor
                transform: scaleX(0);
                transform-origin: right;
                transition: transform 0.5s;
            }
        }
        &:hover{
            .sub__txt::after{
                transform: scaleX(1);
                transform-origin: left;
            }
        }
    }
    &.show{display: block;}
}

/* mobile stylings =================================== */
.mob__index__area{
    +.mob__index__area{
        margin-top: 24px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
}
.mob__nav__btn{
    display: none;
    width: 24px;
}

.mob__head__area{
    @include position(fixed,0,0,0,0);
    margin: auto;    
    visibility: hidden;
    background-color: rgba(0,0,0,0.7);
    z-index: 12;
    .navClose{
        width: 24px; height: 24px;
    }
    .head__box{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 64px);
        height: 100vh;
        padding: 30px 30px 15px;
        background: #1C1E22;
        transform: translateX(-100%);
        // transition-duration: 1500ms;
        // transition-timing-function: ease-in-out;
        @include boxsizing;
    }
    .title__box{
        @include dspFlexCenter;
        margin-bottom: 32px;
        .navClose{
            @include position(absolute, 30px, auto,auto, 30px)
        }
        .header__logo{position: static;transform: inherit;}
    }
    .btn__wallet{
        width: 100%;
        height: 48px;
        padding: 2px;
        border-radius: 12px;
        background: linear-gradient(320.66deg, #29869f 14.75%, #00CAFF 84.81%),
                    linear-gradient(320.66deg, #0190aa 14.75%, #00a2d3 84.81%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        font-family: 'SpaceMonoB';
        @include font(normal,bold,15px,#202024,24px);
        letter-spacing: -0.41px;
        @include boxsizing;
        .txt{padding-left: 8px;}
        &:active,
        &:hover,
        &:focus {
            box-shadow: 0 0 0 8px transparent;
            @include animation(pulse 0.5s 1);
        }
        &:disabled {
            cursor: default;
            opacity: 0.5;
            background: var(--button-bg-color, #025ce2);
        }
    }
}
.mob__gnb__area{
    // display: none;
    .gnb__item{padding-top: 16px;}
    .gnb__item__link{
        font-family: 'SpaceMonoB';
        @include font(normal,bold,20px,rgba(255, 255, 255, 0.5),24px);
        letter-spacing: 0.75px;
        &:hover, &.selected {color: #fff;transition: color .3s;}
    }
}
.mob__sub__seelect{
    .sub__select__item{padding-top: 20px;}
    .sub__select__link{
        font-family: 'WorkSansR';
        @include font(normal,normal,15px,rgba(255, 255, 255, 0.5),20px);
        letter-spacing: -0.24px;
        transition: all .3s ease-in-out;
        .svg__wrap{filter: opacity(0.5)}
        .sub__txt{padding-left: 15px;}
        &:hover,&.active{
            color: #fff;
            .svg__wrap{filter: opacity(1); transition: filter .3s ease-in-out;}
        }
    }
}

@include keyframes(pulse){
    from {
        box-shadow: 0 0 0 0 var(--blue);
    }
}

/* media stylings =================================== */
@include breakPoint(1440px) {
    .header{padding: 24px 20px 0}
}
@include breakPoint(1200px) {
    .gnb__area{
        left: 0rem;
    }
}
@include breakPoint(1000px){
    .header{
        .gnb__area,.select__area{display: none;}
        .header__logo{
            // display: none;
            @include position(absolute,auto,auto,auto,50%);
            transform: translateX(-50%);
        }
        .mob__nav__btn{display: block;}
        &.open{
            .header__logo{z-index: 2;}
            .mob__head__area{
                visibility: inherit;
                .head__box{
                    transform: translateX(0);
                }
            }
            // .mob__gnb__area{display: block;}
        }
        .mob-top-nav{
            display: inline-flex;
            padding: 0 3rem;
            .gnb__item__link{
                @include font(normal,bold,14px,var(--fade-white),18px);
                margin-right: 20px;
                padding: 10px;
                &:hover, &.selected {color: #fff;transition: color .3s;}
            }
        }
    }
}

@include breakPoint(500px){
    .header{
        .mob-top-nav{
            display: flex;
            padding: 0 10px;
            width: 100%;
            justify-content: space-around;
            .gnb__item__link{
                @include font(normal,bold,12.4px,var(--fade-white),18px);
                margin-right: 3px;
                padding: 4px;
                &:hover, &.selected {color: #fff;transition: color .3s;}
            }
        }
    }
}
