@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Nunito:wght@300;400;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Inter(Uniswap) Fonts */
@font-face {
  font-family: 'Inter custom';
  font-weight: 100 900;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url('../fonts/Inter-roman.var.woff2') format('woff2 supports variations(gvar)'),
    url('../fonts/Inter-roman.var.woff2') format('woff2-variations'),
    url('../fonts/Inter-roman.var.woff2') format('woff2');
}

@supports (font-variation-settings: normal) {
  * {
    font-family: 'Inter custom', sans-serif;
  }
}

@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/space-mono-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/space-mono-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/space-mono-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/space-mono-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/space-mono-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/space-mono-v6-latin-regular.svg#SpaceMono') format('svg'); /* Legacy iOS */
}
/* space-mono-700 - latin */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/space-mono-v6-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/space-mono-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/space-mono-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/space-mono-v6-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/space-mono-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/space-mono-v6-latin-700.svg#SpaceMono') format('svg'); /* Legacy iOS */
}

/* work-sans-300 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/work-sans-v11-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/work-sans-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v11-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v11-latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/work-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/work-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v11-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/work-sans-v11-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/work-sans-v11-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v11-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v11-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v11-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v11-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/work-sans-v11-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/work-sans-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v11-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v11-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/work-sans-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/work-sans-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v11-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v11-latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-300italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/work-sans-v11-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/work-sans-v11-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v11-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v11-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v11-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v11-latin-300italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/work-sans-v11-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/work-sans-v11-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v11-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v11-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v11-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v11-latin-italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/work-sans-v11-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/work-sans-v11-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v11-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v11-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v11-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v11-latin-500italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/work-sans-v11-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/work-sans-v11-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v11-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v11-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v11-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v11-latin-600italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/work-sans-v11-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/work-sans-v11-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v11-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v11-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v11-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v11-latin-700italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-700italic - latin */
@font-face {
  font-family: "LED Board-7"; 
  src: url("../fonts/led-board-7.eot");
  src: local(''),
        url("../fonts/led-board-7.eot?#iefix") format("embedded-opentype"),
        url("../fonts/led-board-7.woff2") format("woff2"),
        url("../fonts/led-board-7.woff") format("woff"),
        url("../fonts/led-board-7.ttf") format("truetype"),
        url("../fonts/led-board-7.svg#LED Board-7") format("svg");
}


/* ------------- Roboto Mono ------------------- */

/* Roboto Mono-300 - latin */
@font-face {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/RobotoMono-Light.ttf');
}
/* Roboto Mono - latin */
@font-face {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/RobotoMono-Regular.ttf');
}
/* Roboto Mono-500 - latin */
@font-face {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/RobotoMono-Medium.ttf');
}
/* Roboto Mono-600 - latin */
@font-face {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/RobotoMono-SemiBold.ttf');
}
/* Roboto Mono-700 - latin */
@font-face {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/RobotoMono-Bold.ttf');
}
/* Roboto Monos-300italic - latin */
@font-face {
  font-family: 'Roboto Mono', monospace;
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/RobotoMono-LightItalic.ttf');
}
/* Roboto Mono-500italic - latin */
@font-face {
  font-family: 'Roboto Mono', monospace;
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/RobotoMono-MediumItalic.ttf');
}
/* Roboto Mono-600italic - latin */
@font-face {
  font-family: 'Roboto Mono', monospace;
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/RobotoMono-SemiBoldItalic.ttf');
}
/* Roboto Mono-700italic - latin */
@font-face {
  font-family: 'Roboto Mono', monospace;
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/RobotoMono-BoldItalic.ttf');
}
